import * as React from "react";
import Layout from "../template/Layout";
import SEO from "../template/seo";
import style from "./404.module.scss";

const NotFoundPage: React.FC = () => (
  <Layout withBar={true} contentStyle={style.root}>
    <SEO title="Page Not Found | Prime Draft" description={"Page Not Found on wwww.primedraft.app"} />
    <h1>404: Not Found</h1>
    <p>This page does not exist. Please use the menu bar to navigate to a existing page.</p>
  </Layout>
);

export default NotFoundPage;